import 'babel-polyfill';

import Vue from 'vue';

import svg_icons from './common/svg_icons.vue';

import VueSilentbox from 'vue-silentbox'
Vue.use(VueSilentbox)
// from https://github.com/silencesys/silentbox

import Slick from 'vue-slick';
//https://vuejsfeed.com/blog/implementing-slick-carousel-using-the-vue-slick-component-for-vue-js


// SASS/CSS
import '../../css/sass/public.scss';

// disable the warning about dev/prod
Vue.config.productionTip = false;

new Vue({
    el: '#app',
    components: {
        'svg-icons': svg_icons,
        Slick,
    },

    data: {
        show: false,
        scroll: false,
        slickOptions: {
            slidesToShow: 1,
            infinite: true,
            // swipe: true,
            autoplay: true,
            autoplaySpeed: 6500,
            fade: true,
            dots: true,
            pauseOnHover: false,
            zindex: 1,
            // lazyload: progressive,
            // Any other options that can be got from plugin documentation
        },
    },

    methods: {
        toggle(e) {
            this.show = !this.show
            e.preventDefault();
        },
    },

});


var body = document.body,
    html = document.documentElement;

var height = Math.max(body.scrollHeight, body.offsetHeight,
    html.clientHeight, html.scrollHeight, html.offsetHeight);

var footerHeight = document.getElementsByTagName("FOOTER")[0].offsetHeight;
var calcHeight = (height - footerHeight) - 400;

var myID = document.getElementById("page-menu");

var myScrollFunc = function () {
    if (myID) {
        var y = window.scrollY;
        if (y >= 400 && y <= calcHeight) {
            myID.className = "gallery-page-menu show-menu"
        } else {
            myID.className = "gallery-page-menu show-menu hide-menu"
        }
    }
};


window.addEventListener("scroll", myScrollFunc);


var box = document.querySelector("#galleryMenu");
var body = document.getElementsByTagName('body');
var element = document.getElementById('menu-toggle');

// toggle menu when View our Work is clicked
var toggleGalleryMenu = function (e) {
    if (box.classList.contains("gallery-open")) {
        box.classList.remove("gallery-open");
    } else {
        box.classList.add("gallery-open");
        // Add event listener to document to listen for click
        body[0].addEventListener('click', clickOutside, true);
        e.preventDefault();
    }
};

element.addEventListener("click", toggleGalleryMenu, false);
// Close menu when clicked away
var clickOutside = function (e) {
    if (e.target !== element) {
        box.classList.remove("gallery-open");
        body[0].removeEventListener('click', clickOutside, true);
    }
}